import { useState } from 'react'
import MessageConfig from 'components/modal/MessageConfig'
import { createNewList } from 'slices/list'
import { toast } from 'react-toastify'
import { useAppDispatch } from 'app/hooks'

const newMessages = [
	{
		id: '2GL8DH',
		body: 'Hello Mr. Dover, I would like to review your recent request for medicare insurance savings.',
		status: 'Active',
		send_cap: '472',
		messages_sent: '3,430',
		last_send: 'May 04, 2023 10:45:23',
		date_created: 'January 02, 2023 10:45:23',
	},
]

const CampaignMessages = () => {
	const [open, setOpen] = useState<boolean>(false)
	const [isCreating, setIsCreating] = useState<boolean>(false)
	const dispatch = useAppDispatch()
	const handleOpenModal = async (): Promise<void> => {
		setOpen(true)
	}

	const handleCreateNewList = async (
		name: string,
		description: string
	): Promise<void> => {
		setIsCreating(true)

		try {
			const data = await dispatch(createNewList({ name, description }))
			if (data.payload.success) {
				toast.success('Successfully created a new message')
				setOpen(false)
			} else {
				toast.error(data.payload.message)
			}
		} catch (_) {
			toast.error('Network Error!')
		}

		setIsCreating(false)
	}

	return (
		<div className="mx-auto">
			<div className="">
				<MessageConfig
					open={open}
					closeModal={() => setOpen(false)}
					onMessageConfig={handleCreateNewList}
					loading={isCreating}
				/>
				<div className="mt-4 flex items-end justify-between">
					<div className="">
						<button type="button" className="btn-green" onClick={handleOpenModal}>
							New Message
						</button>
					</div>
				</div>
				<div className="mt-2 flow-root">
					<div className="overflow-x-auto bg-white shadow-md">
						<div className="inline-block min-w-full align-middle">
							<table className="">
								<thead>
									<tr>
										<th scope="col">ID</th>
										<th scope="col">Status</th>
										<th scope="col">Body</th>
										<th scope="col">Send Cap</th>
										<th scope="col">Messages Sent</th>
										<th scope="col">Last Sent</th>
										<th scope="col">Date Created</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200">
									{newMessages.map((message) => (
										<tr key={message.id}>
											<td>{message.id}</td>
											<td>{message.status}</td>
											<td>{message.body}</td>
											<td>{message.send_cap}</td>
											<td>{message.messages_sent}</td>
											<td>{message.last_send}</td>
											<td>{message.date_created}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default CampaignMessages
