import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import MainLayout from 'layouts/MainLayout'
import { apiGetCampaigns } from 'api/campaign.api'

// const campaigns = [
// 	{
// 		id: 1,
// 		name: 'Medicare',
// 		status: 'Active',
// 		messages_today: '472',
// 		messages_total: '4,289',
// 		last_send: 'May 04, 2023',
// 	},
// ]

const Campaigns = () => {
	const [campaigns, setCampaigns] = useState<Record<string, any>[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const navigate = useNavigate()

	useEffect(() => {
		;(async () => {
			try {
				const res = await apiGetCampaigns()

				if (res.success) {
					setCampaigns(res.data.campaigns)
				} else {
					toast.error(res.message)
				}
			} catch (err: any) {
				toast.error(err.message || 'Network Error')
			} finally {
				setLoading(false)
			}
		})()
	})

	return (
		<MainLayout title="Parcel - Campaigns">
			<div className="mx-auto mt-10 max-w-7xl">
				<div className="flex items-end justify-between">
					<button
						type="button"
						className="btn-green"
						onClick={() => navigate('/campaigns/new-campaign')}
					>
						New Campaign
					</button>
				</div>
				{loading ? (
					<p className="mt-4 text-gray-500">Loading ...</p>
				) : (
					<div className="mt-2 flow-root">
						<div className="overflow-x-auto bg-white shadow-md">
							<div className="inline-block min-w-full align-middle">
								<table>
									<thead>
										<tr>
											<th scope="col">Name</th>
											<th scope="col">Status</th>
											<th scope="col">Messages Sent Today</th>
											<th scope="col">Total Messages Sent</th>
											<th scope="col">Last Send</th>
										</tr>
									</thead>
									<tbody>
										{campaigns.map((campaign) => (
											<tr
												key={campaign.id}
												onClick={() => navigate(`/campaigns/${campaign.id}`)}
											>
												<td>{campaign.name}</td>
												<td>{campaign.status}</td>
												<td>{campaign.messages_today}</td>
												<td>{campaign.messages_total}</td>
												<td>{campaign.last_send}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				)}
			</div>
		</MainLayout>
	)
}

export default Campaigns
