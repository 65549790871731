import React from 'react'

const classNames = {
	th: 'whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold',
	td: 'align-top whitespace-nowrap px-2 py-2 text-xs font-medium text-gray-900',
}

interface RecordsProps {
	rows: any[]
}

const Records: React.FC<RecordsProps> = ({ rows }) => {
	return (
		<div className="overflow-x-auto block mx-4" style={{ height: 'calc(100vh - 220px)' }}>
			<table>
				<thead className="sticky top-0">
					<tr>
						<th scope="col" className={classNames.th}>
							record_id
						</th>
						<th scope="col" className={classNames.th}>
							record_date
						</th>
						<th scope="col" className={classNames.th}>
							record_timestamp
						</th>
						<th scope="col" className={classNames.th}>
							record_data.key
						</th>
						<th scope="col" className={classNames.th}>
							record_data.value.string_value
						</th>
						<th scope="col" className={classNames.th}>
							record_data.value.int_value
						</th>
						<th scope="col" className={classNames.th}>
							record_source.key
						</th>
						<th scope="col" className={classNames.th}>
							record_source.value.string_value
						</th>
						<th scope="col" className={classNames.th}>
							record_source.value.int_value
						</th>
						<th scope="col" className={classNames.th}>
							record_offer_event.name
						</th>
						<th scope="col" className={classNames.th}>
							record_offer_event. campaign_id
						</th>
						<th scope="col" className={classNames.th}>
							record_offer_event.offer_id
						</th>
						<th scope="col" className={classNames.th}>
							record_offer_event.timestamp
						</th>
						<th scope="col" className={classNames.th}>
							record_offer_event.code
						</th>
						<th scope="col" className={classNames.th}>
							record_offer_event.transaction_id
						</th>
						<th scope="col" className={classNames.th}>
							communication.key
						</th>
						<th scope="col" className={classNames.th}>
							communication.value
						</th>
					</tr>
				</thead>
				<tbody className="">
					{rows.map((row, ind) => (
						<tr key={ind} className='vertical-border'>
							<td className={classNames.td}>
								<div>{row.record_id}</div>
							</td>
							<td className={classNames.td}>
								{row.record_date.toString().slice(0, 4) +
									'-' +
									row.record_date.toString().slice(4, 6) +
									'-' +
									row.record_date.toString().slice(6, 8)}
							</td>
							<td className={classNames.td}>{row.record_timestamp}</td>
							<td className={classNames.td}>
								<div className="space-y-2">
									{row.record_data.map((data: any, ind: number) => (
										<p key={ind}>{data.key}</p>
									))}
								</div>
							</td>
							<td className={classNames.td}>
								<div className="space-y-2">
									{row.record_data.map((data: any, ind: number) => (
										<React.Fragment key={ind}>
											{data.value.string_value ? (
												<p>{data.value.string_value}</p>
											) : (
												<p className="text-gray-400 italic">-</p>
											)}
										</React.Fragment>
									))}
								</div>
							</td>
							<td className={classNames.td}>
								<div className="space-y-2">
									{row.record_data.map((data: any, ind: number) => (
										<React.Fragment key={ind}>
											{data.value.int_value ? (
												<p>{data.value.int_value}</p>
											) : (
												<p className="text-gray-400 italic">-</p>
											)}
										</React.Fragment>
									))}
								</div>
							</td>
							<td className={classNames.td}>
								<div className="space-y-2">
									{row.record_source.map((data: any, ind: number) => (
										<p key={ind}>{data.key}</p>
									))}
								</div>
							</td>
							<td className={classNames.td}>
								<div className="space-y-2">
									{row.record_source.map((data: any, ind: number) => (
										<React.Fragment key={ind}>
											{data.value.string_value ? (
												<p>{data.value.string_value}</p>
											) : (
												<p className="text-gray-400 italic">-</p>
											)}
										</React.Fragment>
									))}
								</div>
							</td>
							<td className={classNames.td}>
								<div className="space-y-2">
									{row.record_source.map((data: any, ind: number) => (
										<React.Fragment key={ind}>
											{data.value.int_value ? (
												<p>{data.value.int_value}</p>
											) : (
												<p className="text-gray-400 italic">-</p>
											)}
										</React.Fragment>
									))}
								</div>
							</td>
							<td className={classNames.td}>{row.record_offer_event?.name}</td>
							<td className={classNames.td}>{row.record_offer_event?.campaign_id}</td>
							<td className={classNames.td}>{row.record_offer_event?.offer_id}</td>
							<td className={classNames.td}>{row.record_offer_event?.timestamp}</td>
							<td className={classNames.td}>{row.record_offer_event?.code}</td>
							<td className={classNames.td}>{row.record_offer_event?.transaction_id}</td>
							<td className={classNames.td}>
								<div className="space-y-2">
									{row.communication.map((data: any, ind: number) => (
										<p key={ind}>{data.key}</p>
									))}
								</div>
							</td>
							<td className={classNames.td}>
								<div className="space-y-2">
									{row.communication.map((data: any, ind: number) => (
										<p key={ind}>{data.value}</p>
									))}
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default Records
