import axios from 'utils/axios'

export const apiGetInformationForNewCampaign = async (): Promise<any> => {
	try {
		const { data } = await axios.get('/campaigns/get-info-for-new-campaign')
		return data
	} catch (err) {
		throw err
	}
}

export const apiCreateCampaign = async (params: any): Promise<any> => {
	try {
		const { data } = await axios.post('/campaigns', params)
		return data
	} catch (err) {
		throw err
	}
}

export const apiGetCampaigns = async (): Promise<any> => {
	try {
		const { data } = await axios.get('/campaigns')
		return data
	} catch (err) {
		throw err
	}
}

export const apiGetCampaignById = async (id: string): Promise<any> => {
	try {
		const { data } = await axios.get(`/campaigns/${id}`)
		return data
	} catch (err) {
		throw err
	}
}

export const apiUpdateCampaign = async (params: {
	id: string
	campaignData: Record<string, any>
}): Promise<any> => {
	try {
		const { data } = await axios.put(`/campaigns/${params.id}`, params.campaignData)
		return data
	} catch (err) {
		throw err
	}
}
