import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import MainLayout from 'layouts/MainLayout'
import CampaignConfig from 'components/CampaignConfig'
import { apiCreateCampaign } from 'api/campaign.api'
import { useAppDispatch } from 'app/hooks'
import { setLoading } from 'slices/user'

const NewCampaign = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const onSubmit = async (campaignData: Record<string, any>): Promise<void> => {
		try {
			dispatch(setLoading(true))

			const res = await apiCreateCampaign(campaignData)

			if (res.success) {
				toast.success('Successfully created a new campaign')
				navigate('/campaigns/new-messages')
			} else {
				toast.error(res.message)
			}
		} catch (err: any) {
			toast.error(err.message || 'Network Error')
		} finally {
			dispatch(setLoading(false))
		}
	}

	return (
		<MainLayout title="Parcel - Campaigns">
			<div className="container">
				<CampaignConfig onSubmit={onSubmit} saveButton="Save & Continue to Messages" />
			</div>
		</MainLayout>
	)
}

export default NewCampaign
