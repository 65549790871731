import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
	apiGetAllLists,
	apiCreateNewList,
	apiGetListDetails,
	apiUpdateList,
	apiDeleteList,
	apiEnableEndpoint,
} from 'api/list.api'
import {
	apiAddNewFields,
	apiDeleteField,
	apiGetAllFields,
	apiSetTokenActive,
} from 'api/manage-field.api'
import type { Record, Row } from 'pages/Lists/ManageFields/ManageTable'

interface IList {
	lists: any[]
	listDetails: any
	listFields: any[]
	tableMetadata: any
}

const initialState: IList = {
	lists: [],
	listDetails: null,
	listFields: [],
	tableMetadata: {},
}

export const getAllList = createAsyncThunk('list/getAllList', async () => {
	try {
		const data = await apiGetAllLists()
		return data
	} catch (err) {
		throw err
	}
})

export const createNewList = createAsyncThunk(
	'list/createNewList',
	async (params: { name: string; description: string }): Promise<any> => {
		try {
			const data = await apiCreateNewList(params)
			return data
		} catch (err) {
			throw err
		}
	}
)

export const getListDetails = createAsyncThunk(
	'list/getListDetails',
	async (id: string): Promise<any> => {
		try {
			const data = await apiGetListDetails(id)
			return data
		} catch (err) {
			throw err
		}
	}
)

export const updateList = createAsyncThunk(
	'list/updateList',
	async (params: { id: string; name: string; description: string }): Promise<any> => {
		try {
			const data = await apiUpdateList(params)
			return data
		} catch (err) {
			throw err
		}
	}
)

export const deleteList = createAsyncThunk(
	'list/deleteList',
	async (id: string): Promise<any> => {
		try {
			const data = await apiDeleteList(id)
			return data
		} catch (err) {
			throw err
		}
	}
)

export const getAllFields = createAsyncThunk('list/getAllFields', async () => {
	try {
		const data = await apiGetAllFields()
		return data
	} catch (err) {
		throw err
	}
})

export const addNewFields = createAsyncThunk(
	'list/addNewFields',
	async (params: { record: Record; rows: Row[] }) => {
		try {
			const data = await apiAddNewFields(params)
			return data
		} catch (err) {
			throw err
		}
	}
)

export const setTokenActive = createAsyncThunk(
	'/list/manageFields/setTokenActive',
	async (params: { id: number; isActive: boolean }) => {
		try {
			const data = await apiSetTokenActive(params)
			return data
		} catch (err) {
			throw err
		}
	}
)

export const deleteField = createAsyncThunk('list/deleteField', async (id: number) => {
	try {
		const data = await apiDeleteField(id)
		return data
	} catch (_) {
		throw Error()
	}
})

export const enableEndpoint = createAsyncThunk(
	'list/enableEndpoint',
	async (params: { id: string; enabled: boolean }) => {
		try {
			const data = await apiEnableEndpoint(params)
			return data
		} catch (_) {
			throw Error()
		}
	}
)

export const list = createSlice({
	name: 'list',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getAllList.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.lists = action.payload.lists
				state.tableMetadata = action.payload.tableMetadata
			}
		})

		builder.addCase(getAllList.rejected, (state) => {
			state.lists = []
			throw Error()
		})

		builder.addCase(createNewList.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.lists = action.payload.lists
			}
		})

		builder.addCase(createNewList.rejected, (state, action: PayloadAction<any>) => {
			throw Error()
		})

		builder.addCase(getListDetails.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.listDetails = null
			}
		})

		builder.addCase(getListDetails.rejected, (state, action: PayloadAction<any>) => {
			throw Error()
		})

		builder.addCase(updateList.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.lists = action.payload.lists
			}
		})

		builder.addCase(deleteList.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.lists = action.payload.lists
			}
		})

		builder.addCase(getAllFields.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.listFields = action.payload.rows
			}
		})

		builder.addCase(getAllFields.rejected, () => {
			throw Error()
		})

		builder.addCase(addNewFields.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.listFields = action.payload.rows
			}
		})

		builder.addCase(addNewFields.rejected, () => {
			throw Error()
		})

		builder.addCase(deleteField.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.listFields = action.payload.rows
			}
		})

		builder.addCase(deleteField.rejected, () => {
			throw Error()
		})

		builder.addCase(enableEndpoint.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.lists = action.payload.lists
			}
		})

		builder.addCase(enableEndpoint.rejected, () => {
			throw Error()
		})

		builder.addCase(setTokenActive.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.listFields = action.payload.rows
			}
		})
	},
})

export default list.reducer
