import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import MainLayout from 'layouts/MainLayout'
import DateRangePicker from 'components/DateRangePicker'
import Search from 'components/Search'
import Records from './Records'
import Settings from './Settings'
import { useAppSelector } from 'app/hooks'
import { RootState } from 'app/store'
import { apiGetListDetails } from 'api/list.api'
import { cx } from 'utils'

const tabs = ['Overview', 'Records', 'Settings']

const ListDetails = () => {
	const [listData, setListData] = useState<Record<any, any>>({})
	const [selectedTab, setSelectedTab] = useState(tabs[1])
	const [rows, setRows] = useState<any[]>([])
	const { id } = useParams()
	const lists = useAppSelector((state: RootState) => state.list.lists)

	useEffect(() => {
		if (!id) return
		for (let list of lists) {
			if (list.listId === id) {
				setListData(list)
			}
		}
	}, [id, lists])

	useEffect(() => {
		if (!id) return
		;(async () => {
			try {
				const res = await apiGetListDetails(id)

				if (res.success) {
					setRows(res.rows)
				} else {
					toast.error(res.message)
				}
			} catch (err: any) {
				toast.error(err.message || 'Network Error')
			}
		})()
	}, [id])

	const onDateRangeChange = () => {}

	const onSearch = (arg: string): void => {}

	return (
		<MainLayout title="List - Detail">
			<div className="container mt-10">
				<div>
					<nav aria-label="Breadcrumb">
						<ol className="flex items-center space-x-4">
							<li>
								<div className="flex">
									<Link
										to="/lists"
										className="text-sm font-medium text-gray-500 hover:text-gray-700"
									>
										Lists
									</Link>
								</div>
							</li>
							<li>
								<div className="flex items-center">
									<ChevronRightIcon
										className="h-5 w-5 flex-shrink-0 text-gray-400"
										aria-hidden="true"
									/>
									<button className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
										{id}
									</button>
								</div>
							</li>
						</ol>
					</nav>
				</div>
				<div className="flex items-end gap-4">
					<nav className="flex w-fit space-x-4 rounded-md bg-white p-1" aria-label="Tabs">
						{tabs.map((tab) => (
							<div
								className={cx(
									tab === selectedTab
										? 'bg-indigo-100 text-indigo-700'
										: 'text-gray-500 hover:text-gray-700',
									'cursor-pointer rounded-md px-3 py-2 text-sm font-medium'
								)}
								aria-current={tab === selectedTab ? 'page' : undefined}
								onClick={() => setSelectedTab(tab)}
								key={tab}
							>
								{tab}
							</div>
						))}
					</nav>
					<Search className="ml-auto" onSearch={onSearch} />
					<DateRangePicker onChange={onDateRangeChange} />
				</div>
			</div>
			<div className="mt-2">
				{selectedTab === 'Records' && <Records rows={rows} />}
				{selectedTab === 'Settings' && <Settings id={id} listData={listData} />}
			</div>
		</MainLayout>
	)
}

export default ListDetails
