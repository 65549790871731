import axios from 'utils/axios'
import type { Record, Row } from 'pages/Lists/ManageFields/ManageTable'

export const apiGetAllFields = async (): Promise<any> => {
	try {
		const { data } = await axios.get('/lists/manage-fields/')
		return data
	} catch (err) {
		throw err
	}
}

export const apiAddNewFields = async (params: {
	record: Record
	rows: Row[]
}): Promise<any> => {
	try {
		const { data } = await axios.post('/lists/manage-fields/', params)
		return data
	} catch (err) {
		throw err
	}
}

export const apiSetTokenActive = async (params: {
	id: number
	isActive: boolean
}): Promise<any> => {
	try {
		const { data } = await axios.put(
			`/lists/manage-fields/set-token-active/${params.id}`,
			{ isActive: params.isActive }
		)
		return data
	} catch (err) {
		throw err
	}
}

export const apiDeleteField = async (id: number): Promise<any> => {
	try {
		const { data } = await axios.delete(`/lists/manage-fields/${id}`)
		return data
	} catch (err) {
		throw err
	}
}
