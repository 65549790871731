import axios from 'utils/axios'

export const apiLogin = async ({ email, password }): Promise<any> => {
	try {
		const { data } = await axios.post('/auth/login', { email, password })
		return data
	} catch (err) {
		throw err
	}
}

export const apiAuthenticate = async (): Promise<any> => {
	try {
		const { data } = await axios.get('/auth/authenticate')
		return data
	} catch (err) {
		throw err
	}
}
