const menu = [
	{ label: 'Dashboard', to: '/dashboard' },
	{ label: 'Campaigns', to: '/campaigns' },
	{ label: 'Reporting', to: '/reporting' },
	{ label: 'Lists', to: '/lists' },
	{ label: 'Offers', to: '/offers' },
	{ label: 'Logs', to: '/logs' },
]

export default menu
