import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import MainLayout from 'layouts/MainLayout'
import OfferModal from 'components/modal/OfferModal'
import {
	apiAddOffer,
	apiReadAllOffers,
	apiEditOffer,
	apiDeleteOffer,
} from 'api/offer.api'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'

const Offers = () => {
	const [offers, setOffers] = useState<Record<string, any>[]>([])
	const [modalOpen, setModalOpen] = useState<boolean>(false)
	const [submitting, setSubmitting] = useState<boolean>(false)
	const [submissionType, setSubmissionType] = useState<'add' | 'edit'>('add')
	const [defaultValues, setDefaultValues] = useState<Record<string, any>>({})

	useEffect(() => {
		;(async (): Promise<void> => {
			const data = await apiReadAllOffers()

			if (data.success) {
				setOffers(data.offers)
			} else {
				toast.error(data.message)
			}
		})()
	}, [])

	const handleOpenModal = (id: number): void => {
		if (id === -1) {
			setDefaultValues({})
			setSubmissionType('add')
		} else {
			setDefaultValues(offers.filter((offer) => offer.id === id)[0])
			setSubmissionType('edit')
		}
		setModalOpen(true)
	}

	const submitOffer = async (values: any): Promise<void> => {
		try {
			setSubmitting(true)

			if (submissionType === 'add') {
				const data = await apiAddOffer(values)

				if (data.success) {
					setOffers(data.offers)
					toast.success('Successfully added a new offer')
				} else {
					toast.error(data.message)
				}
			} else if (submissionType === 'edit') {
				const data = await apiEditOffer(defaultValues.id, values)

				if (data.success) {
					setOffers(data.offers)
					toast.success('Successfully updated')
				} else {
					toast.error(data.message)
				}
			}
		} catch (_) {
			toast.error('Network Error')
		} finally {
			setSubmitting(false)
			setModalOpen(false)
		}
	}

	const handleDeleteOffer = async (id: number): Promise<void> => {
		if (!window.confirm('Are you sure you really want to delete this offer?')) return

		try {
			const data = await apiDeleteOffer(id)

			if (data.success) {
				setOffers(data.offers)
				toast.success('Successfully deleted')
			} else {
				toast.error(data.message)
			}
		} catch (_) {
			toast.error('Network Error')
		}
	}

	return (
		<MainLayout title="Parcel - Campaigns">
			<OfferModal
				open={modalOpen}
				closeModal={() => setModalOpen(false)}
				submissionType={submissionType}
				submitOffer={submitOffer}
				submitting={submitting}
				defaultValues={defaultValues}
			/>
			<div className="mx-auto mt-10 max-w-7xl">
				<div className="flex items-end justify-between">
					<div className="">
						<button
							type="button"
							className="btn-green"
							onClick={() => handleOpenModal(-1)}
						>
							Add Offer
						</button>
					</div>
				</div>
				<div className="mt-2 flow-root">
					<div className="overflow-x-auto bg-white shadow-md">
						<div className="inline-block min-w-full align-middle">
							<table>
								<thead>
									<tr>
										<th scope="col">Brand</th>
										<th scope="col">Vertical</th>
										<th scope="col">Name</th>
										<th scope="col">URL</th>
										<th scope="col">Date Created</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									{offers.map((offer, ind) => (
										<tr key={`offer-${ind}`}>
											<td>{offer.brand}</td>
											<td>{offer.vertical}</td>
											<td>{offer.name}</td>
											<td>{offer.url}</td>
											<td>
												{new Date(offer.createdAt).toLocaleString('en-US', {
													year: 'numeric',
													month: 'long',
													day: '2-digit',
												})}
											</td>
											<td className="text-right">
												<PencilSquareIcon
													className="inline-block h-5 w-5 cursor-pointer"
													onClick={() => handleOpenModal(offer.id)}
												/>
												<TrashIcon
													className="ml-2 inline-block h-5 w-5 cursor-pointer text-red-700"
													onClick={() => handleDeleteOffer(offer.id)}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</MainLayout>
	)
}
export default Offers
