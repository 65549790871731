import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import Pagination from 'rc-pagination'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import 'rc-pagination/assets/index.css'
import MainLayout from 'layouts/MainLayout'
import DateRangePicker from 'components/DateRangePicker'
import { apiGetMessageEventLogs } from 'api/log.api'

const eventOptions = [
	{ label: 'All Events', value: '' },
	{ label: 'Message Sent', value: 'message_sent' },
	{ label: 'Message Error', value: 'message_error' },
	{ label: 'Message Unsubscribe', value: 'message_unsubscribe' },
	{ label: 'Message Click', value: 'message_click' },
	{ label: 'Message Conversion', value: 'message_conversion' },
]

const Logs = () => {
	const [events, setEvents] = useState<Record<string, any>[]>([])
	const [eventName, setEventName] = useState<string>('')
	const [keyword, setKeyword] = useState<string>('')
	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: 'date',
	})
	const [pageCount, setPageCount] = useState<number>(1)
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		handleSearch()
	}, [currentPage, dateRange, eventName]) //eslint-disable-line

	const handleSearch = async () => {
		try {
			setLoading(true)

			const res = await apiGetMessageEventLogs({
				dateRange,
				keyword,
				eventName,
				currentPage,
			})

			if (res.success) {
				setEvents(res.data.events)
				setPageCount(res.data.pageCount)
				toast.success('Successfully loaded event logs')
			} else {
				toast.error(res.message)
			}
		} catch (err: any) {
			toast.error(err.message || 'Network Error')
		} finally {
			setLoading(false)
		}
	}

	const handlePageChange = (page: number) => {
		setCurrentPage(page)
	}

	return (
		<MainLayout title="Parcel - Logs" className="">
			<div className="mx-4 mt-10">
				<div className="flex justify-between">
					<select
						id="log_filter"
						className="app-input w-48 appearance-none"
						onChange={(e) => setEventName(e.target.value)}
					>
						{eventOptions.map((option, ind) => (
							<option value={option.value} key={ind}>
								{option.label}
							</option>
						))}
					</select>
					<div className="flex gap-4">
						<div className="flex gap-1">
							<input
								type="text"
								id="log_search"
								className="app-input w-60"
								placeholder="Search..."
								onChange={(e) => setKeyword(e.target.value)}
							/>
							<button
								className="btn-primary disabled:cursor-not-allowed disabled:bg-gray-500"
								onClick={handleSearch}
								disabled={loading}
							>
								Search
							</button>
						</div>
						<DateRangePicker onChange={setDateRange} />
						<Pagination
							className="pagination"
							current={currentPage}
							onChange={handlePageChange}
							total={pageCount}
							pageSize={1}
							simple={true}
						/>
					</div>
				</div>
				<div className="table-window mt-2 block overflow-x-auto">
					{loading ? (
						<div className="mt-36 w-full text-center">
							<ArrowPathIcon className="animate mx-auto h-20 w-20 animate-spin text-primary" />
							<span className="mt-2 text-gray-600">Loading...</span>
						</div>
					) : events.length === 0 ? (
						<div className="mt-36 w-full text-center">
							<span className="text-gray-600">No data to display</span>
						</div>
					) : (
						<table>
							<thead className="sticky top-0">
								<tr>
									<th scope="col">event date</th>
									<th scope="col">event timestamp</th>
									<th scope="col">event name</th>
									<th scope="col">message id</th>
									<th scope="col">sending platform</th>
									<th scope="col">platform id</th>
									<th scope="col">modem id</th>
									<th scope="col">integration id</th>
									<th scope="col">sending number</th>
									<th scope="col">sending carrier</th>
									<th scope="col">receiving number</th>
									<th scope="col">receiving carrier</th>
									<th scope="col">creative id</th>
									<th scope="col">campaign id</th>
									<th scope="col">link id</th>
									<th scope="col">error id</th>
									<th scope="col">error name</th>
									<th scope="col">offer id</th>
									<th scope="col">offer name</th>
									<th scope="col">offer revenue</th>
								</tr>
							</thead>
							<tbody className="text-xs">
								{events.map((event) => (
									<tr
										className="vertical-border"
										title={event.body}
										key={event.event_timestamp}
									>
										<td>{new Date(+event.event_timestamp).toLocaleString()}</td>
										<td>{event.event_timestamp}</td>
										<td>{event.event_name}</td>
										<td>{event.message_id}</td>
										<td>{event.sending_platform}</td>
										<td>{event.platform_id}</td>
										<td>{event.modem_id}</td>
										<td>{event.integration_id}</td>
										<td>{event.sending_number}</td>
										<td>{event.sending_carrier}</td>
										<td>{event.receiving_number}</td>
										<td>{event.receiving_carrier}</td>
										<td>{event.creative_id}</td>
										<td>{event.campaign_id}</td>
										<td>{event.link_id}</td>
										<td>{event.error_id}</td>
										<td>{event.error_name}</td>
										<td>{event.offer_id}</td>
										<td>{event.offer_name}</td>
										<td>{event.offer_revenue}</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</MainLayout>
	)
}

export default Logs
