import axios from 'utils/axios'

export const apiGetIntegration = async (): Promise<any> => {
	try {
		const { data } = await axios.get('/integrations')
		return data
	} catch (err) {
		throw err
	}
}

export const apiUpdateIntegration = async (params: any): Promise<any> => {
	try {
		const { data } = await axios.put('/integrations', { data: params })
		return data
	} catch (err) {
		throw err
	}
}
