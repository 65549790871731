import { Fragment, useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-hot-toast'
import { PulseLoader } from 'react-spinners'
import { Dialog, Transition } from '@headlessui/react'
import InputForm from 'components/form/Input'
import { apiGetAllFields } from 'api/manage-field.api'
import useOnClickOutside from 'hooks/useOnClickOutside'
import validate from 'utils/validation'
import { cx } from 'utils'

interface OfferModalProps {
	open: boolean
	closeModal: () => void
	submissionType: 'add' | 'edit'
	submitOffer: (arg: any) => void
	submitting: boolean
	defaultValues: Record<string, any>
}

const OfferModal: React.FC<OfferModalProps> = ({
	open,
	closeModal,
	submissionType,
	submitOffer,
	submitting,
	defaultValues,
}) => {
	const [fields, setFields] = useState<Record<string, any>[]>([])
	const [offerURL, setOfferURL] = useState<string>('')
	const [showSuggestedTokens, setShowSuggestedTokens] = useState<boolean>(false)
	const [tokenPrefix, setTokenPrefix] = useState<string>('')
	const ref = useRef<any>()

	useEffect(() => {
		;(async () => {
			try {
				const data = await apiGetAllFields()

				if (data.success) {
					setFields([
						...data.rows.filter((row: Record<string, any>) => row.activeToken === true),
					])
				} else {
					toast.error(data.message)
				}
			} catch (err) {
				toast.error('Error while fetching active tokens')
			}
		})()
	}, [])

	useEffect(() => {
		if (!defaultValues) return

		setOfferURL(defaultValues.url)
	}, [defaultValues])

	useOnClickOutside({ ref, onClickOutside: () => setShowSuggestedTokens(false) })

	const handleClose = (): void => {
		if (submitting) return

		submissionType === 'add' && setOfferURL('')
		closeModal()
	}

	const onSubmit = (values: any): void => {
		if (offerURL === '') {
			toast.error('Offer URL is required')
			return
		}

		submitOffer({ ...values, url: offerURL })
	}

	const handleOfferURLChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setOfferURL(e.target.value)

		if (e.target.value.includes('{')) {
			setTokenPrefix(
				e.target.value.slice(e.target.value.lastIndexOf('{'), e.target.value.length)
			)
			setShowSuggestedTokens(true)
		} else {
			setTokenPrefix('')
			setShowSuggestedTokens(false)
		}
	}

	const handleTokenClick = (token: string): void => {
		setOfferURL((prev) => prev.slice(0, prev.lastIndexOf('{')) + token)
		setTokenPrefix(token)
		setShowSuggestedTokens(false)
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-50" onClose={handleClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
						<Form
							initialValues={defaultValues}
							onSubmit={onSubmit}
							render={({ handleSubmit }) => (
								<form onSubmit={handleSubmit} className="w-full max-w-4xl">
									<Transition.Child
										as={Fragment}
										enter="ease-out duration-300"
										enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
										enterTo="opacity-100 translate-y-0 sm:scale-100"
										leave="ease-in duration-200"
										leaveFrom="opacity-100 translate-y-0 sm:scale-100"
										leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									>
										<Dialog.Panel className="relative my-8 w-full max-w-4xl transform rounded-lg bg-white p-6 text-left shadow-xl transition-all ">
											<div>
												<Dialog.Title
													as="h3"
													className="text-base font-semibold leading-6 text-gray-900"
												>
													{submissionType === 'add' ? 'Add a new offer' : 'Edit offer'}
												</Dialog.Title>
												<div className="mt-4 space-y-2">
													<Field name="brand" validate={validate}>
														{(props) => <InputForm label="Brand Name" {...props} />}
													</Field>
													<Field name="vertical" validate={validate}>
														{(props) => (
															<InputForm
																component="select"
																label="Vertical"
																options={[
																	'Auto Insurance',
																	'Health Insurance',
																	'Medicare',
																	'Penny Pincher',
																]}
																{...props}
															/>
														)}
													</Field>
													<Field name="name" validate={validate}>
														{(props) => <InputForm label="Offer Name" {...props} />}
													</Field>
													<div className="relative" ref={ref}>
														<label htmlFor="OfferURL">
															<span className="text-sm">Offer URL</span>
															<input
																id="OfferURL"
																className="app-input mt-0"
																value={offerURL}
																onChange={handleOfferURLChange}
															/>
														</label>
														<div
															className={cx(
																'absolute left-0 bottom-0 max-h-[100px] w-full translate-y-full overflow-y-auto border border-gray-500 bg-white transition-opacity duration-300 ease-in-out',
																showSuggestedTokens
																	? 'pointer-events-auto opacity-100'
																	: 'pointer-events-none opacity-0'
															)}
														>
															{fields
																.filter((field) => field.token.includes(tokenPrefix))
																.map((field, ind) => (
																	<div
																		className="cursor-pointer px-2 py-1 hover:bg-gray-300"
																		onClick={() => handleTokenClick(field.token)}
																		key={ind}
																	>
																		{field.name}{' '}
																		<span className="text-sm text-gray-500">
																			{field.token}
																		</span>
																	</div>
																))}
														</div>
													</div>
												</div>
											</div>
											<div className="mt-6 flex space-x-2">
												<button
													type="submit"
													className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
												>
													Submit
												</button>
												<button
													className="w-full rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-80"
													onClick={handleClose}
												>
													Cancel
												</button>
											</div>
											{submitting ? (
												<div className="absolute top-0 left-0 flex h-full w-full items-center justify-center backdrop-blur-[2px]">
													<PulseLoader color="rgb(79, 70, 229)" />
												</div>
											) : (
												<></>
											)}
										</Dialog.Panel>
									</Transition.Child>
								</form>
							)}
						/>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default OfferModal
