import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link, useParams } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import MainLayout from 'layouts/MainLayout'
import CampaignMessages from './CampaignMessages'
import CampaignSettings from './CampaignSettings'
import CampaignReporting from './CampaignReporting'
import { apiGetCampaignById } from 'api/campaign.api'
import { cx } from 'utils'

const tabs = ['Reporting', 'Messages', 'Settings']

const CampaignOverview = () => {
	const [campaignData, setCampaignData] = useState<Record<string, any>>({})
	const [loadingStatus, setLoadingStatus] = useState<number>(0)
	const [selectedTab, setSelectedTab] = useState(tabs[0])
	const { campaignId } = useParams()

	useEffect(() => {
		if (!campaignId) return
		;(async () => {
			try {
				const res = await apiGetCampaignById(campaignId)

				if (res.success) {
					setCampaignData(res.data.campaign)
					setLoadingStatus(1)
				} else {
					setLoadingStatus(-1)
					toast.error(res.message)
				}
			} catch (err: any) {
				setLoadingStatus(-1)
				toast.error(err.message || 'Network Error')
			}
		})()
	}, [campaignId])

	return (
		<MainLayout>
			{loadingStatus === 0 ? (
				<p className="mt-4 text-center text-gray-500">Loading ...</p>
			) : loadingStatus === -1 ? (
				<p className="mt-4 text-center text-gray-500">
					There was an error while loading the campaign data
				</p>
			) : (
				<>
					<nav aria-label="Breadcrumb" className="mt-6 ml-4">
						<ol className="flex items-center">
							<li>
								<div className="mr-2 flex">
									<Link
										to="/campaigns"
										className="text-lg font-medium text-gray-500 hover:text-gray-700"
									>
										Campaigns
									</Link>
								</div>
							</li>
							<li>
								<div className="flex items-center">
									<ChevronRightIcon
										className="h-5 w-5 flex-shrink-0 text-gray-400"
										aria-hidden="true"
									/>
									<button className="ml-2 text-lg font-medium text-gray-800">
										{campaignData.name}
									</button>
								</div>
							</li>
						</ol>
					</nav>
					<div className="mt-4">
						<div className="flex items-center">
							<nav className="ml-4 flex gap-1 py-2" aria-label="Tabs">
								{tabs.map((tab) => (
									<div
										className={cx(
											tab === selectedTab
												? 'bg-indigo-100 text-indigo-700'
												: 'text-gray-800 hover:bg-indigo-50 hover:text-gray-700',
											'cursor-pointer px-4 py-2 text-sm font-medium'
										)}
										aria-current={tab === selectedTab ? 'page' : undefined}
										onClick={() => setSelectedTab(tab)}
										key={tab}
									>
										{tab}
									</div>
								))}
							</nav>
						</div>
					</div>

					<div className="mx-4 mt-4">
						{selectedTab === 'Reporting' && <CampaignReporting />}
						{selectedTab === 'Messages' && <CampaignMessages />}
						{selectedTab === 'Settings' && (
							<CampaignSettings campaignData={campaignData} />
						)}
					</div>
				</>
			)}
		</MainLayout>
	)
}
export default CampaignOverview
