import { Fragment, useEffect, useId, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-hot-toast'
import { TrashIcon } from '@heroicons/react/24/outline'
import CheckboxForm from 'components/form/CheckboxForm'
import { apiGetInformationForNewCampaign } from 'api/campaign.api'
import { scheduleOptions, timeOptions } from 'config'
import validate from 'utils/validation'

interface InputFormProps extends React.InputHTMLAttributes<HTMLInputElement> {
	component?: 'input' | 'select'
	label?: string
	options?: Record<string, any>[]
	input: any
	meta: any
}

export const InputForm: React.FC<InputFormProps> = ({
	component = 'input',
	label,
	options = [],
	input,
	meta,
	...props
}) => {
	const id = useId()

	return (
		<label htmlFor={id}>
			{label ? <span className="input-label mb-2">{label}</span> : <></>}
			{component === 'input' ? (
				<input id={id} className="app-input mt-2" {...input} {...props} />
			) : component === 'select' ? (
				<select id={id} className="app-input" {...input} {...props}>
					<option value="" disabled></option>
					{options.map((option: Record<string, any>, ind) => (
						<option value={option.value} key={`option-${ind}`}>
							{option.label}
						</option>
					))}
				</select>
			) : (
				<></>
			)}
			{meta.touched && meta.error && (
				<p className="m1-1 mt-0.5 text-xs text-red-500">{meta.error}</p>
			)}
		</label>
	)
}

interface Props {
	onSubmit: React.Dispatch<React.SetStateAction<any>>
	saveButton?: string
	campaignData?: Record<string, any>
}

const CampaignConfig: React.FC<Props> = ({ onSubmit, saveButton, campaignData }) => {
	const [disabledTwilioCarriers, setDisabledTwilioCarriers] = useState<string[]>([])
	const [integrations, setIntegrations] = useState<Record<string, any>>({})
	const [lists, setLists] = useState<Record<string, any>[]>([])
	const [offers, setOffers] = useState<Record<string, any>[]>([])
	const [tempSchedules, setTempSchedules] = useState<boolean[]>([true])
	const [initialValues, setInitialValues] = useState<Record<string, any>>({})

	useEffect(() => {
		;(async () => {
			try {
				const res = await apiGetInformationForNewCampaign()

				if (res.success) {
					setLists(res.data.lists)
					setOffers(res.data.offers)
					setIntegrations(JSON.parse(res.data.integrations[0].data))

					let temp: string[] = []

					for (let item of JSON.parse(res.data.integrations[0].data)
						.carrierRestrictions) {
						if (item.type === 'Disabled') {
							temp.push(item.carrier)
						}
					}

					setDisabledTwilioCarriers(temp)
				} else {
					toast.error(res.message)
				}
			} catch (err: any) {
				toast.error(err.message || 'Network Error')
			}
		})()
	}, [])

	useEffect(() => {
		if (!campaignData) return

		const tempValues = JSON.parse(campaignData.formValues)

		for (let j = 0; j < 999; j++) {
			if (tempValues[`days-${j}`]) {
				delete tempValues[`days-${j}`]
				delete tempValues[`from-${j}`]
				delete tempValues[`to-${j}`]
			} else {
				break
			}
		}

		let i = 0,
			arr: boolean[] = []
		for (let schedule of JSON.parse(campaignData.sendingSchedule)) {
			arr.push(true)
			tempValues[`days-${i}`] = schedule.days
			tempValues[`from-${i}`] = schedule.from
			tempValues[`to-${i}`] = schedule.to
			i++
		}

		setInitialValues(tempValues)
		setTempSchedules(arr)
	}, [campaignData])

	const onFormSubmit = (values: any) => {
		if (values.twilio && +values.twilioDailySendCap > integrations.dailySendCap) {
			toast.error(
				`Daily send cap in twilio sending system can't be more than ${integrations.dailySendCap}`
			)
			return
		}

		let sendingSchedule: Record<string, any>[] = []

		for (let i = 0; i < tempSchedules.length; i++) {
			if (tempSchedules[i]) {
				sendingSchedule.push({
					days: values[`days-${i}`],
					from: values[`from-${i}`],
					to: values[`to-${i}`],
				})
			} else {
			}
		}

		let sendingSystem: Record<string, any> = {}

		if (values.twilio) {
			sendingSystem.twilio = {
				dailySendCap: values.twilioDailySendCap,
				excludeCarriers: {
					'AT&T': values.twilioCarrier0 ? true : false,
					'T-Mobile': values.twilioCarrier1 ? true : false,
					Verizon: values.twilioCarrier2 ? true : false,
				},
			}
		}
		if (values.simBlaster) {
			sendingSystem.simBlaster = {}
		}
		if (values.smtp) {
			sendingSystem.smtp = {}
		}

		const data = {
			name: values.campaignName,
			startDate: values.startDate,
			listId: +values.listId,
			dailySendCap: +values.dailySendCap,
			minDaysSinceLastMsg: +values.minDaysSinceLastMsg,
			sendingSchedule: JSON.stringify(sendingSchedule),
			demographic: '',
			sendingSystem: JSON.stringify(sendingSystem),
			offerId: +values.offerId,
			shortURLDomain: '',
			formValues: JSON.stringify(values),
		}

		onSubmit(data)
	}

	const handleAddSchedule = (): void => {
		setTempSchedules((prev) => [...prev, true])
	}

	const handleRemoveSchedule = (ind: number): void => {
		let temp = tempSchedules
		temp[ind] = false

		setTempSchedules([...temp])
	}

	return (
		<Form
			onSubmit={onFormSubmit}
			initialValues={initialValues}
			render={({ handleSubmit, values }) => (
				<form onSubmit={handleSubmit} className="my-10 grid grid-cols-3 gap-4">
					<div className="col-span-2">
						<div className="bg-white shadow-md">
							<h3 className="bg-gray-900 py-2 pl-5 text-lg font-medium text-gray-100">
								Campaign Settings
							</h3>
							<div className="p-5">
								<div className="w-1/3">
									<Field name="campaignName" validate={validate}>
										{(props) => <InputForm label="Campaign Name" {...props} />}
									</Field>
								</div>
								<div className="mt-6 w-1/3">
									<Field name="startDate" validate={validate}>
										{(props) => <InputForm type="date" label="Start Date" {...props} />}
									</Field>
								</div>
								<div className="mt-6 w-1/3">
									<Field name="listId" validate={validate}>
										{(props) => (
											<InputForm
												component="select"
												label="Select List"
												options={lists.map((list: Record<string, any>) => ({
													label: list.name,
													value: list.id,
												}))}
												{...props}
											/>
										)}
									</Field>
								</div>
								<div className="mt-6 w-1/3">
									<Field name="dailySendCap" validate={validate}>
										{(props) => (
											<InputForm label="Daily Send Cap" placeholder="1,000" {...props} />
										)}
									</Field>
								</div>
								<div className="mt-6 w-1/3">
									<Field name="minDaysSinceLastMsg" validate={validate}>
										{(props) => (
											<InputForm
												type="tel"
												label="Minimum Days Since Last Message"
												placeholder="15"
												{...props}
											/>
										)}
									</Field>
								</div>
								<div className="mt-6">
									<p className="text-sm font-medium text-gray-900">Sending Schedule</p>
									{tempSchedules.map((active, ind) => (
										<Fragment key={ind}>
											{active ? (
												<div className="mt-2 flex gap-2">
													<div className="w-36">
														<Field name={`days-${ind}`} validate={validate}>
															{(props) => (
																<InputForm
																	component="select"
																	options={scheduleOptions}
																	{...props}
																/>
															)}
														</Field>
													</div>
													{values[`days-${ind}`] !== '-1' && (
														<>
															<div className="w-32">
																<Field name={`from-${ind}`} validate={validate}>
																	{(props) => (
																		<InputForm
																			component="select"
																			options={timeOptions}
																			{...props}
																		/>
																	)}
																</Field>
															</div>
															<span className="mt-2">to</span>
															<div className="w-32">
																<Field name={`to-${ind}`} validate={validate}>
																	{(props) => (
																		<InputForm
																			component="select"
																			options={timeOptions}
																			{...props}
																		/>
																	)}
																</Field>
															</div>
														</>
													)}
													<TrashIcon
														className="ml-2 mt-2 inline-block h-5 w-5 cursor-pointer text-red-700"
														onClick={() => handleRemoveSchedule(ind)}
													/>
												</div>
											) : (
												<></>
											)}
										</Fragment>
									))}
									<div
										className="mt-2 w-fit cursor-pointer text-sm text-blue-700 underline"
										onClick={handleAddSchedule}
									>
										+ add schedule
									</div>
								</div>
							</div>
						</div>
						<div className="mt-10 bg-white shadow-md">
							<h3 className="bg-gray-900 py-2 pl-5 text-lg font-medium text-gray-100">
								Filters
							</h3>
							<div className="p-5">
								<div>
									<p className="text-sm font-medium text-gray-900">Demographic</p>
									<div className="mt-2 flex items-center gap-2">
										<select
											id="location"
											name="location"
											className="app-input w-36"
											defaultValue="Age"
										>
											<option>Age</option>
											<option>Region</option>
											<option>City</option>
										</select>
										<select
											id="location"
											name="location"
											className="app-input w-32"
											defaultValue="between"
										>
											<option>over</option>
											<option>under</option>
											<option>between</option>
										</select>
										<input
											type="tel"
											name="minThreshold"
											id="minThreshold"
											className="app-input w-32"
											placeholder="40 to 50"
										/>
									</div>
								</div>
								<div className="mt-2 text-sm text-blue-700 underline">+ add row</div>
							</div>
						</div>
						<div className="mt-10 bg-white shadow-md">
							<h3 className="bg-gray-900 py-2 pl-5 text-lg font-medium text-gray-100">
								Sending System
							</h3>
							<div className="p-5">
								<div className="grid gap-5">
									<Field type="checkbox" name="twilio">
										{(props) => <CheckboxForm label="Twilio" {...props} />}
									</Field>
									<Field type="checkbox" name="simBlaster">
										{(props) => <CheckboxForm label="SIM Blaster" {...props} />}
									</Field>
									<Field type="checkbox" name="smtp">
										{(props) => <CheckboxForm label="SMTP" {...props} />}
									</Field>
								</div>
								{values.twilio && (
									<div className="mt-10">
										<h4 className="font-medium text-gray-800">Twilio</h4>
										<div className="mt-2 border border-gray-300 p-5">
											<div className="w-1/3">
												<Field name="twilioDailySendCap" validate={validate}>
													{(props) => <InputForm label="Daily Send Cap" {...props} />}
												</Field>
											</div>
											<div className="mt-5">
												<h5 className="text-sm font-medium text-gray-900">
													Exclude Carriers
												</h5>
												<div className="mt-2 grid gap-2">
													<Field
														type="checkbox"
														name="twilioCarrier0"
														disabled={disabledTwilioCarriers.includes('AT&T')}
													>
														{(props) => <CheckboxForm label="AT&T" {...props} />}
													</Field>
													<Field
														type="checkbox"
														name="twilioCarrier1"
														disabled={disabledTwilioCarriers.includes('T-Mobile')}
													>
														{(props) => <CheckboxForm label="T-Mobile" {...props} />}
													</Field>
													<Field
														type="checkbox"
														name="twilioCarrier2"
														disabled={disabledTwilioCarriers.includes('Verizon')}
													>
														{(props) => <CheckboxForm label="Verizon" {...props} />}
													</Field>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="mt-10 bg-white shadow-md">
							<h3 className="bg-gray-900 py-2 pl-5 text-lg font-medium text-gray-100">
								Offer Settings
							</h3>
							<div className="p-5">
								<div className="w-1/3">
									<Field name="offerId" validate={validate}>
										{(props) => (
											<InputForm
												component="select"
												label="Select Offer"
												options={offers.map((list: Record<string, any>) => ({
													label: list.brand,
													value: list.id,
												}))}
												{...props}
											/>
										)}
									</Field>
								</div>
								<div className="mt-6 w-1/3">
									<Field name="shortURLDomain">
										{(props) => (
											<InputForm
												component="select"
												label="Select Short URL Domain"
												options={[]}
												{...props}
											/>
										)}
									</Field>
								</div>
							</div>
						</div>
						<div className="mt-8 flex justify-end">
							<button type="submit" className="btn-green">
								{saveButton}
							</button>
						</div>
					</div>
					<div className="sticky top-28 h-60 bg-white shadow-md">
						<div className="bg-gray-900 py-2 pl-5 text-lg font-medium text-gray-100">
							Stats
						</div>
						<div className="p-5 text-lg">
							<div className="grid grid-cols-2 gap-4">
								<div className="text-right">
									<ul>
										<li>List Size:</li>
										<li>Amount Filtered:</li>
									</ul>
								</div>
								<div>
									<ul>
										<li>1,787,445</li>
										<li>28,445</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</form>
			)}
		/>
	)
}
export default CampaignConfig
