import axios from 'utils/axios'

export const apiGetMessageEventLogs = async ({
	dateRange,
	keyword,
	eventName,
	currentPage,
}): Promise<any> => {
	try {
		const { data } = await axios.post('/logs/message-events', {
			dateRange,
			keyword,
			eventName,
			currentPage,
		})
		return data
	} catch (err) {
		throw err
	}
}
