import { Link, useLocation } from 'react-router-dom'
import ProfileMenu from 'components/ProfileMenu'
import menu from 'config/menu'
import { cx } from 'utils'

const Header = () => {
	const { pathname } = useLocation()

	return (
		<header className="sticky top-0 z-40 bg-gray-800 shadow-md">
			<div className="px-4">
				<div className="flex h-12 items-center gap-8">
					<div className="mr-auto flex items-end text-xl font-bold leading-none text-white">
						<img src="/images/logo.png" alt="" className="h-6 w-6" />
						ARCEL
					</div>
					<ProfileMenu />
				</div>
			</div>
			<div className="bg-white">
				<nav className="flex py-2 px-4">
					{menu.map((item) => (
						<Link
							to={item.to}
							className={cx(
								pathname.includes(item.to) ? 'bg-indigo-500 text-white' : 'text-gray-600',
								'py-1 px-4 text-sm'
							)}
							key={item.label}
						>
							{item.label}
						</Link>
					))}
				</nav>
			</div>
		</header>
	)
}

export default Header
