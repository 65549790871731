import { useId } from 'react'

interface InputFormProps extends React.InputHTMLAttributes<HTMLInputElement> {
	component?: 'input' | 'select'
	label: string
	options?: any[]
	input: any
	meta: any
}

const InputForm: React.FC<InputFormProps> = ({
	component = 'input',
	label,
	options = [],
	input,
	meta,
	...props
}) => {
	const id = useId()

	return (
		<div>
			<label htmlFor={id}>
				<span className="text-sm">{label}</span>
				{component === 'input' ? (
					<input id={id} className="app-input mt-0" {...input} {...props} />
				) : component === 'select' ? (
					<select id={id} className="app-input mt-0" {...input} {...props}>
						<option></option>
						{options.map((option, ind) => (
							<option value={option} key={`option-${ind}`}>
								{option}
							</option>
						))}
					</select>
				) : (
					<></>
				)}
				{meta.touched && meta.error && (
					<p className="mt-0.5 ml-1 text-xs text-red-500">{meta.error}</p>
				)}
			</label>
		</div>
	)
}

export default InputForm
