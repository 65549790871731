import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { apiLogin } from 'api/user.api'

interface IUser {
	loading: boolean
	isLoggedIn: boolean
}

const initialState: IUser = {
	loading: false,
	isLoggedIn: false,
}

export const login = createAsyncThunk(
	'user/login',
	async (params: { email: string; password: string }): Promise<any> => {
		try {
			const data = await apiLogin(params)
			return data
		} catch (err) {
			throw err
		}
	}
)

export const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.isLoggedIn = true
			}
		})
		builder.addCase(login.rejected, (err) => {
			throw err
		})
	},
})

export const { setLoading } = user.actions

export default user.reducer
