import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { ArrowPathIcon, ArrowUpIcon } from '@heroicons/react/24/outline'
import MainLayout from 'layouts/MainLayout'
import DateRangePicker from 'components/DateRangePicker'
import { apiGetReporting } from 'api/reporting.api'
import { getPercentage } from 'utils'

const dimensionOptions = [
	{ label: 'Event Date', value: 'original_message_timestamp' },
	{ label: 'Body Text', value: 'body' },
	{ label: 'Sending Number', value: 'sending_number' },
	{ label: 'Creative ID', value: 'creative_id' },
	{ label: 'Sending Platform', value: 'sending_platform' },
	{ label: 'Campaign ID', value: 'campaign_id' },
]

const ths = [
	{ label: 'Messages Sent', key: 'message_sent' },
	{ label: 'Clicks', key: 'message_click' },
	{ label: 'Click Rate', key: 'click_rate' },
	{ label: 'Conversions', key: 'message_conversion' },
	{ label: 'Sent Conversion Rate', key: 'sent_conversion_rate' },
	{ label: 'Click Conversion Rate', key: 'click_conversion_rate' },
	{ label: 'Unsubscribes', key: 'message_unsubscribe' },
	{ label: 'Unsubscribe Rate', key: 'unsubscribe_rate' },
	{ label: 'Revenue', key: 'revenue' },
	{ label: 'RPMS', key: 'rpms' },
	{ label: 'Errors', key: 'message_error' },
	{ label: 'Error Rate', key: 'error_rate' },
]

const Reporting = () => {
	const [reportings, setReportings] = useState<Record<string, any>[]>([])
	const [filteredReportings, setFilteredReportings] = useState<Record<string, any>[]>([])
	const [dimension, setDimension] = useState<string>('original_message_timestamp')
	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: 'date',
	})
	const [orderBy, setOrderBy] = useState<string>('')
	const [order, setOrder] = useState<number>(1)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		getReporting()
	}, [dateRange, dimension]) //eslint-disable-line

	const getReporting = async (): Promise<void> => {
		try {
			setLoading(true)

			const res = await apiGetReporting({ dateRange, dimension })

			if (res.success) {
				setReportings(res.data.reportings)
				toast.success('Successfully loaded reportings')
			} else {
				toast.error(res.message)
			}
		} catch (err: any) {
			toast.error(err.message || 'Network Error')
		} finally {
			setLoading(false)
		}
	}

	const updateSearch = (arg: string) => {
		if (arg === '') return

		if (orderBy === arg) {
			setOrder((prev) => 0 - prev)
		} else {
			setOrder(1)
		}

		setOrderBy(arg)
	}

	useEffect(() => {
		let temp = reportings.map((row) => ({
			...row,
			click_rate: getPercentage(row.message_click, row.message_sent),
			sent_conversion_rate: getPercentage(row.message_conversion, row.message_sent),
			click_conversion_rate: getPercentage(row.message_conversion, row.message_click),
			unsubscribe_rate: getPercentage(row.message_unsubscribe, row.message_sent),
			rpms: Number((getPercentage(row.revenue, row.message_sent) / 10000).toFixed(2)),
			error_rate: getPercentage(row.message_error, row.message_sent),
		}))
		if (temp.length === 0 || orderBy === '') {
			setFilteredReportings(temp)
			return
		}
		setFilteredReportings([
			...temp.sort((a: any, b: any) =>
				a[orderBy] < b[orderBy] ? 1 * order : -1 * order
			),
		])
	}, [reportings]) //eslint-disable-line

	useEffect(() => {
		if (filteredReportings.length === 0 || orderBy === '') return

		setFilteredReportings((prev) => [
			...prev.sort((a, b) => (a[orderBy] < b[orderBy] ? 1 * order : -1 * order)),
		])
	}, [orderBy, order]) //eslint-disable-line

	return (
		<MainLayout title="Parcel - Reporting">
			<div className="mx-4 mt-10">
				<div className="flex items-end justify-between">
					<select
						className="app-input w-48 appearance-none"
						onChange={(e) => setDimension(e.target.value)}
					>
						{dimensionOptions.map((dimension, ind) => (
							<option value={dimension.value} key={`dimension-${ind}`}>
								{dimension.label}
							</option>
						))}
					</select>
					<div className="flex items-end gap-2">
						<DateRangePicker onChange={setDateRange} />
						<button
							className="btn-primary disabled:cursor-not-allowed disabled:bg-gray-400 "
							onClick={getReporting}
							disabled={loading}
						>
							Refresh
						</button>
					</div>
				</div>
				<div className="table-window mt-2 block overflow-x-auto">
					{loading ? (
						<div className="mt-36 w-full text-center">
							<ArrowPathIcon className="animate mx-auto h-20 w-20 animate-spin text-primary" />
							<span className="mt-2 text-gray-600">Loading... </span>
						</div>
					) : filteredReportings.length === 0 ? (
						<div className="mt-36 w-full text-center text-gray-600">
							No data to display
						</div>
					) : (
						<table className="w-full border bg-white text-left text-sm text-gray-500">
							<thead className="sticky top-0">
								<tr>
									<th
										scope="col"
										className="cursor-pointer px-2 py-3 hover:opacity-75"
										onClick={() => updateSearch(dimension)}
									>
										<div className="flex items-center gap-2">
											{dimension === 'original_message_timestamp'
												? 'Event Date'
												: dimension.replace('_', ' ')}
											{dimension === orderBy && (
												<ArrowUpIcon
													className={`h-4 ${order === 1 ? 'rotate-180' : 'rotate-0'}`}
												/>
											)}
										</div>
									</th>
									{ths.map((th, ind) => (
										<th
											scope="col"
											className="cursor-pointer px-2 py-3 hover:opacity-75"
											onClick={() => updateSearch(th.key)}
											key={ind}
										>
											<div className="flex items-center gap-2">
												{th.label}
												{th.key === orderBy && th.key !== '' && (
													<ArrowUpIcon
														className={`h-4 ${order === 1 ? 'rotate-180' : 'rotate-0'}`}
													/>
												)}
											</div>
										</th>
									))}
								</tr>
							</thead>
							<tbody className="text-xs">
								{filteredReportings.map((reporting, ind) => (
									<tr className="vertical-border" title={reporting.body} key={ind}>
										<td>
											{dimension === 'original_message_timestamp'
												? reporting.date
												: reporting[dimension]}
										</td>
										<td>{reporting.message_sent}</td>
										<td>{reporting.message_click}</td>
										<td>{reporting.click_rate + '%'}</td>
										<td>{reporting.message_conversion}</td>
										<td>{reporting.sent_conversion_rate + '%'}</td>
										<td>{reporting.click_conversion_rate + '%'}</td>
										<td>{reporting.message_unsubscribe}</td>
										<td>{reporting.unsubscribe_rate + '%'}</td>
										<td>$ {reporting.revenue / 100}</td>
										<td>$ {reporting.rpms}</td>
										<td>{reporting.message_error}</td>
										<td>{reporting.error_rate + '%'}</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</MainLayout>
	)
}

export default Reporting
