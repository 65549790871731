import axios from 'utils/axios'

export const apiAddOffer = async (params: any): Promise<any> => {
	try {
		const { data } = await axios.post('/offers', params)
		return data
	} catch (err) {
		throw err
	}
}

export const apiReadAllOffers = async (): Promise<any> => {
	try {
		const { data } = await axios.get('/offers')
		return data
	} catch (err) {
		throw err
	}
}

export const apiEditOffer = async (id: number, params: any): Promise<any> => {
	try {
		const { data } = await axios.put(`/offers/${id}`, params)
		return data
	} catch (err) {
		throw err
	}
}

export const apiDeleteOffer = async (id: number): Promise<any> => {
	try {
		const { data } = await axios.delete(`/offers/${id}`)
		return data
	} catch (err) {
		throw err
	}
}
