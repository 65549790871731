import axios from 'utils/axios'

export const apiGetAllLists = async (): Promise<any> => {
	try {
		const { data } = await axios.get('/lists')
		return data
	} catch (err) {
		throw err
	}
}

export const apiCreateNewList = async (params: {
	name: string
	description: string
}): Promise<any> => {
	try {
		const { data } = await axios.post('/lists', params)
		return data
	} catch (err) {
		throw err
	}
}

export const apiGetListDetails = async (id: string): Promise<any> => {
	try {
		const { data } = await axios.get(`/lists/${id}`)
		return data
	} catch (err) {
		throw err
	}
}

export const apiUpdateList = async (params: {
	id: string
	name: string
	description: string
}): Promise<any> => {
	try {
		const { data } = await axios.put(`/lists/${params.id}`, params)
		return data
	} catch (err) {
		throw err
	}
}

export const apiDeleteList = async (id: string): Promise<any> => {
	try {
		const { data } = await axios.delete(`/lists/${id}`)
		return data
	} catch (err) {
		throw err
	}
}

export const apiEnableEndpoint = async (params: {
	id: string
	enabled: boolean
}): Promise<any> => {
	try {
		const { data } = await axios.post(`/lists/enable-endpoint/${params.id}`, {
			enabled: params.enabled,
		})
		return data
	} catch (err) {
		throw err
	}
}
