import MainLayout from 'layouts/MainLayout'

const Dashboard = () => {
	return (
		<MainLayout title="Parcel - Dashboard">
			<div className="container">
				<h3 className="mt-16 text-base font-semibold leading-6 text-gray-900">
					Last 30 days
				</h3>
				<dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
					<div className="px-4 py-5 sm:p-6">
						<dt className="text-base font-normal text-gray-900">Total Subscribers</dt>
						<dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
							<div className="flex items-baseline text-2xl font-semibold text-indigo-600">
								71,897
								<span className="ml-2 text-sm font-medium text-gray-500">
									from 70,946
								</span>
							</div>

							<div className="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
								<svg
									className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
									/>
								</svg>
								<span className="sr-only"> Increased by </span>
								12%
							</div>
						</dd>
					</div>

					<div className="px-4 py-5 sm:p-6">
						<dt className="text-base font-normal text-gray-900">Avg. Open Rate</dt>
						<dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
							<div className="flex items-baseline text-2xl font-semibold text-indigo-600">
								58.16%
								<span className="ml-2 text-sm font-medium text-gray-500">
									from 56.14%
								</span>
							</div>

							<div className="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
								<svg
									className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
									/>
								</svg>
								<span className="sr-only"> Increased by </span>
								2.02%
							</div>
						</dd>
					</div>

					<div className="px-4 py-5 sm:p-6">
						<dt className="text-base font-normal text-gray-900">Avg. Click Rate</dt>
						<dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
							<div className="flex items-baseline text-2xl font-semibold text-indigo-600">
								24.57%
								<span className="ml-2 text-sm font-medium text-gray-500">
									from 28.62%
								</span>
							</div>

							<div className="inline-flex items-baseline rounded-full bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800 md:mt-2 lg:mt-0">
								<svg
									className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"
									/>
								</svg>
								<span className="sr-only"> Decreased by </span>
								4.05%
							</div>
						</dd>
					</div>
				</dl>
			</div>
		</MainLayout>
	)
}

export default Dashboard
