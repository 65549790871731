import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import MainLayout from 'layouts/MainLayout'
import CreateNewListModal from 'components/modal/CreateNewList'
import { RootState } from 'app/store'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getAllList, createNewList } from 'slices/list'

const Lists = () => {
	const [open, setOpen] = useState<boolean>(false)
	const [isCreating, setIsCreating] = useState<boolean>(false)
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const lists = useAppSelector((state: RootState) => state.list.lists)
	const tableMetadata = useAppSelector((state: RootState) => state.list.tableMetadata)

	useEffect(() => {
		dispatch(getAllList())
	}, [dispatch])

	const handleOpenModal = async (): Promise<void> => {
		setOpen(true)
	}

	const handleCreateNewList = async (
		name: string,
		description: string
	): Promise<void> => {
		setIsCreating(true)

		try {
			const data = await dispatch(createNewList({ name, description }))
			if (data.payload.success) {
				toast.success('Successfully created a new list')
				setOpen(false)
			} else {
				toast.error(data.payload.message)
			}
		} catch (_) {
			toast.error('Network Error!')
		}

		setIsCreating(false)
	}

	const handleClickList = (list: any) => {
		navigate(`/lists/${list.listId}`)
	}

	return (
		<MainLayout title="Parcel - Lists">
			<div className="mx-4 mt-10">
				<CreateNewListModal
					open={open}
					closeModal={() => setOpen(false)}
					onCreateNewList={handleCreateNewList}
					loading={isCreating}
				/>
				<div className="mx-auto max-w-7xl">
					<div className="flex items-end justify-between">
						<div className="">
							<button type="button" className="btn-green" onClick={handleOpenModal}>
								Create New List
							</button>
						</div>
						<button
							type="button"
							className="btn-white"
							onClick={() => navigate('/lists/manage-fields')}
						>
							Manage Fields
						</button>
					</div>
					<div className="mt-2 flow-root">
						<div className="overflow-x-auto bg-white shadow-md">
							<div className="inline-block min-w-full align-middle">
								<table>
									<thead>
										<tr>
											<th scope="col">Name</th>
											<th scope="col">Description</th>
											<th scope="col">Records</th>
											<th scope="col">Last Entry</th>
											<th scope="col">Date Created</th>
										</tr>
									</thead>
									<tbody>
										{lists.map((list: any, index) => (
											<tr
												className="cursor-pointer hover:opacity-80"
												onClick={() => handleClickList(list)}
												key={index}
											>
												<td>{list.name}</td>
												<td>{list.description}</td>
												<td>{tableMetadata[list.listId].numRows}</td>
												<td>
													{new Date(
														+tableMetadata[list.listId].lastModifiedTime
													).toLocaleString('en-US', {
														year: 'numeric',
														month: 'long',
														day: '2-digit',
														hour: 'numeric',
														minute: '2-digit',
														hour12: true,
													})}
												</td>
												<td>
													{new Date(list.createdAt).toLocaleString('en-US', {
														year: 'numeric',
														month: 'long',
														day: '2-digit',
														// hour: 'numeric',
														// minute: '2-digit',
														// hour12: true,
													})}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default Lists
