import { toast } from 'react-toastify'
import CampaignConfig from 'components/CampaignConfig'
import { apiUpdateCampaign } from 'api/campaign.api'
import { useAppDispatch } from 'app/hooks'
import { setLoading } from 'slices/user'

interface CampaignSettingsProps {
	campaignData: Record<string, any>
}

const CampaignSettings: React.FC<CampaignSettingsProps> = ({ campaignData }) => {
	const dispatch = useAppDispatch()

	const onSubmit = async (data: Record<string, any>): Promise<void> => {
		try {
			dispatch(setLoading(true))

			const res = await apiUpdateCampaign({ id: campaignData.id, campaignData: data })

			if (res.success) {
				toast.success('Successfully updated campaign')
			} else {
				toast.error(res.message)
			}
		} catch (err: any) {
			toast.error(err.message || 'Network Error')
		} finally {
			dispatch(setLoading(false))
		}
	}

	return (
		<div className="container mx-auto">
			<CampaignConfig
				onSubmit={onSubmit}
				saveButton="Save Changes"
				campaignData={campaignData}
			/>
		</div>
	)
}

export default CampaignSettings
