import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import MainLayout from 'layouts/MainLayout'
import { apiGetIntegration, apiUpdateIntegration } from 'api/integration.api'
import { cx } from 'utils'

const carrierOptions = ['AT&T', 'T-Mobile', 'Verizon']

const Integrations = () => {
	const [loading, setLoading] = useState<boolean>(true)
	const [dailySendCap, setDailySendCap] = useState<number>()
	const [sendRateLimit, setSendRateLimit] = useState<number>()
	const [sendRateLimitUnit, setSendRateLimitUnit] = useState<string>('hour')
	const [phoneNumberRefreshRate, setPhoneNumberRefreshRate] = useState<number>()
	const [carrierRestrictions, setCarrierRestrictions] = useState<Record<string, any>[]>([
		{ carrier: 'AT&T', type: 'Messages', limit: undefined },
	])

	useEffect(() => {
		;(async () => {
			try {
				const res = await apiGetIntegration()

				if (res.success) {
					const integration = JSON.parse(res.data.data)

					setDailySendCap(integration.dailySendCap)
					setSendRateLimit(integration.sendRateLimit)
					setSendRateLimitUnit(integration.sendRateLimitUnit)
					setPhoneNumberRefreshRate(integration.phoneNumberRefreshRate)
					setCarrierRestrictions(integration.carrierRestrictions)
					setLoading(false)

					toast.success('Successfully loaded integrations')
				} else {
					toast.error(res.message)
				}
			} catch (err: any) {
				toast.error(err.message || 'Network error')
			}
		})()
	}, [])

	const handleSelectCarrier = (
		e: React.ChangeEvent<HTMLSelectElement>,
		ind: number
	): void => {
		for (let option of carrierRestrictions) {
			if (option.carrier === e.target.value) {
				toast.error('Cannot set same carrier twice')
				return
			}
		}

		let temp = carrierRestrictions

		temp[ind].carrier = e.target.value
		setCarrierRestrictions([...temp])
	}

	const handleChangeCarrierType = (
		e: React.ChangeEvent<HTMLSelectElement>,
		ind: number
	): void => {
		let temp = [...carrierRestrictions]

		temp[ind].type = e.target.value
		if (e.target.value === 'Disabled') {
			temp[ind].limit = undefined
		}

		setCarrierRestrictions([...temp])
	}

	const handleChangeCarrierLimit = (
		e: React.ChangeEvent<HTMLInputElement>,
		ind: number
	): void => {
		let temp = [...carrierRestrictions]

		temp[ind].limit = +e.target.value

		setCarrierRestrictions([...temp])
	}

	const handleAddCarrier = (): void => {
		if (carrierRestrictions.length >= 3) {
			toast.error('Cannot add more carriers')
			return
		}

		setCarrierRestrictions((prev) => [
			...prev,
			{
				carrier: carrierOptions.filter(
					(option) => carrierRestrictions.findIndex((re) => re.carrier === option) === -1
				)[0],
				type: 'Messages',
				limit: undefined,
			},
		])
	}

	const handleRemoveCarrier = (ind: number): void => {
		if (carrierRestrictions.length === 1) {
			toast.error('Cannot remove all carriers')
		}

		setCarrierRestrictions((prev) => [
			...prev.filter((_, index: number) => index !== ind),
		])
	}

	const handleUpdate = async (): Promise<void> => {
		if (!window.confirm('Are you sure you want to update integration?')) return

		const params = {
			dailySendCap: dailySendCap,
			sendRateLimit: sendRateLimit,
			sendRateLimitUnit: sendRateLimitUnit,
			phoneNumberRefreshRate: phoneNumberRefreshRate,
			carrierRestrictions: carrierRestrictions,
		}

		try {
			const data = await apiUpdateIntegration(params)

			if (data.success) {
				toast.success('Successfully updated integration')
			} else {
				toast.error(data.message)
			}
		} catch (err: any) {
			toast.error(err.message || 'Network Error')
		}
	}

	return (
		<MainLayout>
			<div className="mx-auto max-w-7xl">
				<div className="mt-10 flow-root">
					<div className="text-xl">Integrations</div>
					<div className="text-md mt-10 font-bold text-gray-800">Twilio</div>
					{loading ? (
						<p>Loading ...</p>
					) : (
						<div className="mt-2 max-w-3xl border border-gray-300 bg-white p-5">
							<div className="">
								<label htmlFor="dailySendCap" className="input-label">
									Daily Send Cap
								</label>
								<div className="mt-2">
									<input
										type="text"
										name="dailySendCap"
										id="dailySendCap"
										className="app-input w-36"
										placeholder="1,000"
										defaultValue={dailySendCap}
										onChange={(e) => setDailySendCap(+e.target.value)}
									/>
								</div>
							</div>

							<div className="mt-4">
								<label htmlFor="sendRateLimit" className="input-label">
									Send Rate Limit
								</label>
								<div className="mt-2">
									<input
										type="text"
										name="sendRateLimit"
										id="sendRateLimit"
										className="app-input inline-block w-36"
										placeholder="1,000"
										defaultValue={sendRateLimit}
										onChange={(e) => setSendRateLimit(+e.target.value)}
									/>
									<select
										id="sendRateLimitUnit"
										name="sendRateLimitUnit"
										className="ml-2 inline-block py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-gray-300 focus:ring-1 focus:ring-indigo-600 sm:text-sm sm:leading-6"
										defaultValue={sendRateLimitUnit}
										onChange={(e) => setSendRateLimitUnit(e.target.value)}
									>
										<option value="hour">per hour</option>
										<option value="day">per day</option>
									</select>
								</div>

								<div className="mt-4">
									<label htmlFor="phoneNumberRefreshRate" className="input-label">
										Phone Number Refresh Rate
									</label>
									<div className="mt-2">
										<input
											type="text"
											name="phoneNumberRefreshRate"
											id="phoneNumberRefreshRate"
											className="app-input inline-block w-36"
											placeholder="1,000"
											defaultValue={phoneNumberRefreshRate}
											onChange={(e) => setPhoneNumberRefreshRate(+e.target.value)}
										/>
										<div className="ml-2 inline-block text-sm text-gray-700">
											Messages
										</div>
									</div>
								</div>

								<div className="mt-4">
									<label htmlFor="carrier" className="input-label">
										Carrier Restrictions
									</label>
									{carrierRestrictions.map((carrierRestriction, ind) => (
										<div className={cx(ind !== 0 ? 'mt-2' : '')} key={ind}>
											<select
												className="inline-block py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-gray-300 focus:ring-1 focus:ring-indigo-600 sm:text-sm sm:leading-6"
												value={carrierRestriction.carrier}
												onChange={(e) => handleSelectCarrier(e, ind)}
											>
												{carrierOptions.map((option, ind) => (
													<option value={option} key={ind}>
														{option}
													</option>
												))}
											</select>
											<select
												className="ml-2 inline-block py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-gray-300 focus:ring-1 focus:ring-indigo-600 sm:text-sm sm:leading-6"
												value={carrierRestriction.type}
												onChange={(e) => handleChangeCarrierType(e, ind)}
											>
												<option value="Messages">Messages</option>
												<option value="Disabled">Disabled</option>
											</select>
											{carrierRestriction.type === 'Messages' && (
												<input
													type="text"
													className="app-input ml-2 inline-block w-36"
													placeholder="1,000"
													defaultValue={carrierRestriction.limit}
													onChange={(e) => handleChangeCarrierLimit(e, ind)}
												/>
											)}
											<div className="ml-2 inline-block text-sm text-gray-700">
												per day
											</div>
											<div
												className="ml-4 inline-block cursor-pointer text-sm text-red-700 underline"
												onClick={() => handleRemoveCarrier(ind)}
											>
												Remove
											</div>
										</div>
									))}
									<div
										className="mt-2 ml-2 cursor-pointer text-sm text-indigo-600 underline"
										onClick={handleAddCarrier}
									>
										+ Add Row
									</div>
								</div>
							</div>
							<div className="flex justify-end">
								<div
									className="btn-green mt-6 w-24 cursor-pointer"
									onClick={handleUpdate}
								>
									Update
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</MainLayout>
	)
}

export default Integrations
