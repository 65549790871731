import { useEffect } from 'react'

interface Props {
	ref: any
	onClickOutside: () => void
}

const useOnClickOutside = ({ ref, onClickOutside }: Props) => {
	useEffect(() => {
		const handleClick = (event: MouseEvent): void => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClickOutside()
			}
		}

		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	}, [ref]) //eslint-disable-line
}

export default useOnClickOutside
