import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Login from 'pages/Auth/Login'
import Dashboard from 'pages/Dashboard'
import Campaigns from 'pages/Campaigns'
import NewCampaign from 'pages/Campaigns/NewCampaign'
import Reporting from 'pages/Reporting'
import Lists from 'pages/Lists'
import ManageFields from 'pages/Lists/ManageFields'
import Logs from 'pages/Logs'
import ListDetails from 'pages/Lists/Details'
import Offers from 'pages/Offers'
import Integrations from 'pages/Integrations/Integrations'
import CampaignSettings from 'pages/Campaigns/Details/CampaignSettings'
import CampaignOverview from 'pages/Campaigns/Details/CampaignOverview'
import CampaignMessages from 'pages/Campaigns/Details/CampaignMessages'
import NewMessages from 'pages/Campaigns/Messages/NewMessages'
import { apiAuthenticate } from 'api/user.api'

const AppRoutes = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	useEffect(() => {
		;(async () => {
			try {
				if (pathname.includes('/login')) return

				if (!localStorage.getItem('parcel_token')) {
					navigate('/login')
					return
				}

				const res = await apiAuthenticate()

				if (!res.success) {
					navigate('/login')
				}
			} catch (_) {
				navigate('/login')
			}
		})()
	}, [pathname]) //eslint-disable-line

	return (
		<>
			<Routes>
				<Route path="/" element={<Navigate to="/dashboard" replace />} />
				<Route path="/login" element={<Login />} />
				<Route path="dashboard">
					<Route index element={<Dashboard />} />
				</Route>
				<Route path="campaigns">
					<Route index element={<Campaigns />} />
					<Route path="new-campaign">
						<Route index element={<NewCampaign />} />
					</Route>
					<Route path="new-messages">
						<Route index element={<NewMessages />} />
					</Route>
					{/* <Route path="messages" element={<CampaignMessages />} /> */}
					{/* <Route path="settings" element={<CampaignSettings />} /> */}
					<Route path=":campaignId" element={<CampaignOverview />} />
				</Route>
				<Route path="reporting">
					<Route index element={<Reporting />} />
				</Route>
				<Route path="lists">
					<Route index element={<Lists />} />
					<Route path=":id" element={<ListDetails />} />
					<Route path="manage-fields">
						<Route index element={<ManageFields />} />
					</Route>
				</Route>
				<Route path="/offers" element={<Offers />} />
				<Route path="logs">
					<Route index element={<Logs />} />
				</Route>
				<Route path="/integrations" element={<Integrations />} />
			</Routes>
		</>
	)
}

export default AppRoutes
