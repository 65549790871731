import { useId } from 'react'

interface CheckboxFormProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label: string
	input: any
}

const CheckboxForm: React.FC<CheckboxFormProps> = ({ label, input, ...props }) => {
	const id = useId()

	return (
		<div className="flex items-center gap-3">
			<input
				type="checkbox"
				id={id}
				className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
				{...input}
				{...props}
			/>
			<label htmlFor={id} className="text-sm font-medium text-gray-900">
				{label}
			</label>
		</div>
	)
}

export default CheckboxForm
