import MainLayout from 'layouts/MainLayout'

const newMessages = [
	{
		id: '2GL8DH',
		body: 'Hello Mr. Dover, I would like to review your recent request for medicare insurance savings.',
		status: 'Active',
		send_cap: '472',
		messages_sent: 'Member',
		last_send: 'May 04, 2023 10:45:23',
	},
]

const NewMessages = () => {
	return (
		<MainLayout title="Parcel - Campaigns">
			<div className="container mx-auto max-w-7xl py-4">
				<div className="">
					<h1 className="mt-4 border-b border-gray-300 pb-2 text-2xl font-bold leading-7 text-gray-900">
						Messages
					</h1>
					<div className="mt-4 flex items-end justify-between">
						<div className="">
							<button type="button" className="btn-green">
								New Message
							</button>
						</div>
					</div>
					<div className="mt-2 flow-root">
						<div className="overflow-x-auto bg-white shadow-md">
							<div className="inline-block min-w-full align-middle">
								<table className="">
									<thead>
										<tr>
											<th scope="col">ID</th>
											<th scope="col">Status</th>
											<th scope="col">Body</th>
											<th scope="col">Send Cap</th>
											<th scope="col">Messages Sent</th>
											<th scope="col">Last Sent</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200">
										{newMessages.map((message) => (
											<tr key={message.id}>
												<td>{message.id}</td>
												<td>{message.status}</td>
												<td>{message.body}</td>
												<td>{message.send_cap}</td>
												<td>{message.messages_sent}</td>
												<td>{message.last_send}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default NewMessages
