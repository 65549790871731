import axios from 'utils/axios'

export const apiGetReporting = async ({ dateRange, dimension }): Promise<any> => {
	try {
		const { data } = await axios.post('/reporting', { dateRange, dimension })
		return data
	} catch (err) {
		throw err
	}
}
