import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { TrashIcon } from '@heroicons/react/24/outline'
import Toggle from 'components/Toggle'
import { setLoading } from 'slices/user'
import { RootState } from 'app/store'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { addNewFields, deleteField, getAllFields, setTokenActive } from 'slices/list'

export type Record = 'source' | 'record' | 'communication'
export type Field = 'string' | 'integer' | 'boolean'

export interface Row {
	name: string
	label: string
	type: Field
	token: string
	activeToken: boolean
}

const emptyRow: Row = {
	name: '',
	label: '',
	type: 'string',
	token: '',
	activeToken: false,
}

interface Props {
	record: Record
}

const ManageTable: React.FC<Props> = ({ record }) => {
	const [newRows, setNewRows] = useState<Row[]>([])
	const dispatch = useAppDispatch()
	const listFields = useAppSelector((state: RootState) => state.list.listFields)

	useEffect(() => {
		dispatch(getAllFields())
	}, [dispatch])

	const handleAddRow = (): void => {
		setNewRows((prev) => [...prev, emptyRow])
	}

	const handleChange = (ind: number, fieldName: string, newValue: any): void => {
		let prev = [...newRows]
		let temp: any = { ...prev[ind] }

		temp[fieldName] = newValue

		if (fieldName === 'name') {
			let camelCase = newValue.split(' ')
			camelCase[0] = camelCase[0].toLowerCase()
			temp.label = camelCase.join('')
			temp.token = `{${temp.label}}`
		}

		prev[ind] = temp

		setNewRows([...prev])
	}

	const handleRemoveNewRow = (ind: number): void => {
		setNewRows((prev) => prev.filter((_, i) => i !== ind))
	}

	const handleUpdate = async (): Promise<void> => {
		try {
			dispatch(setLoading(true))

			const data = await dispatch(addNewFields({ record, rows: newRows }))
			if (data.payload.success) {
				toast.success('Successfully created new fields')
				setNewRows([emptyRow])
			} else {
				toast.error(data.payload.message)
			}
		} catch (err) {
			toast.error('Network Error')
		} finally {
			dispatch(setLoading(false))
		}
	}

	const handleRemoveRow = async (id: number): Promise<void> => {
		try {
			dispatch(setLoading(true))

			const data = await dispatch(deleteField(id))
			if (data.payload.success) {
				toast.success('Successfully removed field')
			} else {
				toast.error(data.payload.message)
			}
		} catch (err) {
			toast.error('Network Error')
		} finally {
			dispatch(setLoading(false))
		}
	}

	const handleClickToggle = async (id: number, activeToken: boolean): Promise<void> => {
		try {
			dispatch(setLoading(true))

			const { payload } = await dispatch(setTokenActive({ id, isActive: !activeToken }))

			if (payload.success) {
				toast.success('Successfully updated')
			} else {
				toast.error(payload.message)
			}
		} catch (err) {
			toast.error('Network Error')
		} finally {
			dispatch(setLoading(false))
		}
	}

	return (
		<div>
			<h3 className="my-2 text-lg font-bold capitalize">{record} Fields</h3>
			<div className="overflow-x-auto bg-white shadow-md">
				<div className="inline-block min-w-full align-middle">
					<table>
						<thead>
							<tr>
								<th scope="col">Name</th>
								<th scope="col">Label</th>
								<th scope="col">Type</th>
								<th scope="col">Token</th>
								<th scope="col">Active Token</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							{listFields
								.filter((field) => field.recordType === record)
								.map((field) => (
									<tr key={field.id}>
										<td>{field.name}</td>
										<td>{field.label}</td>
										<td>{field.type}</td>
										<td>
											<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
												{field.token}
											</span>
										</td>
										<td>
											<Toggle
												checked={field.activeToken}
												onClick={() => handleClickToggle(field.id, field.activeToken)}
											/>
										</td>
										<td>
											<TrashIcon
												width={24}
												height={24}
												className="cursor-pointer"
												onClick={() => handleRemoveRow(field.id)}
											/>
										</td>
									</tr>
								))}

							{newRows.map((item, ind) => (
								<tr key={ind}>
									<td className={`font-medium text-gray-900`}>
										<input
											className="app-input w-56"
											value={item.name}
											onChange={(e) => handleChange(ind, 'name', e.target.value)}
										/>
									</td>
									<td>
										<input
											className="app-input w-56"
											value={item.label}
											onChange={(e) => handleChange(ind, 'label', e.target.value)}
										/>
									</td>
									<td>
										<select
											className="app-input w-36"
											value={item.type}
											onChange={(e) => handleChange(ind, 'type', e.target.value)}
										>
											{['string', 'integer', 'boolean'].map((_type) => (
												<option value={_type} key={_type}>
													{_type}
												</option>
											))}
										</select>
									</td>
									<td>
										<span className="bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600">
											{item.token}
										</span>
									</td>
									<td>
										<Toggle
											checked={item.activeToken}
											setChecked={() =>
												handleChange(ind, 'activeToken', !item.activeToken)
											}
										/>
									</td>
									<td>
										<TrashIcon
											width={24}
											height={24}
											className="cursor-pointer"
											onClick={() => handleRemoveNewRow(ind)}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<div className="mt-4 flex justify-between">
				<div>
					<button
						type="button"
						className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						onClick={handleAddRow}
					>
						<PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
						Add Row
					</button>
				</div>
				<div>
					<button className="btn-primary" onClick={handleUpdate}>
						Update
					</button>
				</div>
			</div>
		</div>
	)
}

export default ManageTable
