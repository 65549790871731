import { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Bars3Icon } from '@heroicons/react/24/outline'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { cx } from 'utils'

const ProfileMenu = () => {
	const ref = useRef<any>(null)
	const [open, setOpen] = useState<boolean>(false)
	const navigate = useNavigate()

	const handleSingOut = (): void => {
		localStorage.removeItem('parcel_token')
		setOpen(false)
		navigate('/login')
	}

	useOnClickOutside({ ref, onClickOutside: () => setOpen(false) })

	return (
		<div className="relative" ref={ref}>
			<Bars3Icon
				className="h-8 w-8 cursor-pointer rounded-full text-gray-100"
				onClick={() => setOpen((prev) => !prev)}
			/>
			<div
				className={cx(
					'absolute bottom-0 -right-2 translate-y-full rounded bg-white p-3 shadow-md transition-opacity duration-300 ease-in-out',
					open ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
				)}
			>
				<div className="text-gray grid w-32 gap-4 text-sm">
					<p className="cursor-pointer hover:text-indigo-700">Account</p>
					<p className="cursor-pointer hover:text-indigo-700">
						<Link to="/integrations">Integrations</Link>
					</p>
					<p className="cursor-pointer hover:text-indigo-700" onClick={handleSingOut}>
						Sign Out
					</p>
				</div>
			</div>
		</div>
	)
}

export default ProfileMenu
