import { useId, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch } from 'app/hooks'
import { setLoading } from 'slices/user'
import { deleteList, enableEndpoint, updateList } from 'slices/list'
import DeleteListModal from 'components/modal/DeleteList'
import Toggle from 'components/Toggle'

interface Props {
	id: string | undefined
	listData: Record<any, any>
}

const Settings: React.FC<Props> = ({ id, listData }) => {
	const [nameId, descriptionId] = [useId(), useId()]
	const [activated, setActivated] = useState<boolean>(listData.endpointEnabled)
	const [open, setOpen] = useState<boolean>(false)
	const nameRef = useRef<any>(null)
	const descriptionRef = useRef<any>(null)
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const handleOpenModal = (): void => {
		setOpen(true)
	}

	const closeModal = (): void => {
		setOpen(false)
	}

	const handleUpdateList = async (): Promise<void> => {
		try {
			dispatch(setLoading(true))

			const name = nameRef.current.value
			const description = descriptionRef.current.value

			const data = await dispatch(updateList({ id: id || '', name, description }))

			if (data.payload.success) {
				toast.success('Successfully updated')
			} else {
				toast.error(data.payload.message)
			}
		} catch (err: any) {
			toast.error(err.message || 'Network Error')
		} finally {
			dispatch(setLoading(false))
		}
	}

	const onDeleteList = async (): Promise<void> => {
		try {
			dispatch(setLoading(true))

			const data = await dispatch(deleteList(id || ''))

			if (data.payload.success) {
				toast.success(`Successfully deleted ${id}`)
				navigate('/lists')
			} else {
				toast.success(data.payload.message)
			}
		} catch (err: any) {
			toast.error(err.message || 'Network error')
		} finally {
			dispatch(setLoading(false))
		}
	}

	const handleActivateEndpoint = async (): Promise<void> => {
		try {
			dispatch(setLoading(true))

			const { payload } = await dispatch(
				enableEndpoint({ id: id || '', enabled: !activated })
			)

			if (payload.success) {
				setActivated((prev) => !prev)
			} else {
				toast.error(payload.message)
			}
		} catch (_) {
			toast.error('Network error')
		} finally {
			dispatch(setLoading(false))
		}
	}

	return (
		<>
			<DeleteListModal
				open={open}
				closeModal={closeModal}
				name={listData.name}
				onDelete={onDeleteList}
			/>
			<div className="mx-auto w-full max-w-6xl rounded-md border border-gray-500 p-6">
				<div className="space-y-4">
					<div>
						<label htmlFor={nameId} className="input-label">
							Name
						</label>
						<input
							type="text"
							id={nameId}
							className="app-input w-96"
							defaultValue={listData.name}
							ref={nameRef}
						/>
					</div>
					<div>
						<label htmlFor={descriptionId} className="input-label">
							Description
						</label>
						<input
							type="text"
							id={descriptionId}
							className="app-input w-96"
							defaultValue={listData.description}
							ref={descriptionRef}
						/>
					</div>
					<button className="btn-primary" onClick={handleUpdateList}>
						Update
					</button>
				</div>
				<div className="mt-12 space-y-4">
					<h4 className="font-bold">Data submission endpoint</h4>
					<div className="flex items-center space-x-2">
						<span className="text-xs">Activate</span>
						<Toggle checked={activated} setChecked={handleActivateEndpoint} />
					</div>
					<div>
						<label htmlFor="endpoint" className="text-xs">
							API URL
							<input
								type="text"
								id="endpoint"
								className="app-input disabled w-80"
								value={`https://api.parc3l.com/${listData.listId}/`}
							/>
						</label>
					</div>
				</div>
				<div className="mt-12 space-y-4">
					<h4 className="font-bold">Delete {listData.name} from Big Query</h4>
					<button className="btn-primary bg-red-500" onClick={handleOpenModal}>
						Delete List
					</button>
				</div>
			</div>
		</>
	)
}

export default Settings
