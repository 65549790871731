import { ToastContainer } from 'react-toastify'
import { Toaster } from 'react-hot-toast'
import 'react-toastify/dist/ReactToastify.css'

import AppRoutes from './routes'
import Loading from 'components/Loading'

const App = () => {
	return (
		<>
			<AppRoutes />
			<ToastContainer
				autoClose={2000}
				pauseOnHover={false}
				pauseOnFocusLoss={false}
				hideProgressBar
			/>
			<Toaster position="top-right" reverseOrder={false} />
			<Loading />
		</>
	)
}

export default App
